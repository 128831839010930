<template>
  <div class="home">
    <!--banner-->
    <div class="banner">
      <a-carousel :autoplay="true" v-if="carouselList.length >0">
        <div v-for="(item, index) in carouselList" :key="index">
          <a :href="item.imgurl" target="_blank">
            <img :src="item.imgurlPic" alt="">
          </a>
        </div>
      </a-carousel>
    </div>
    <!--通知公告-->
    <div class="notice">
      <div class="container">
        <div class="con-title"><span>通知公告</span></div>
        <div class="notice-list">
          <div class="more-notice"  @click="goRouter('3.3.')">更多通知</div>
          <a-list
            :grid="{ gutter: 24, column: 2 }"
            :data-source="dataNotice.slice(0, 6)"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false">
                <template slot="title">
                  <i></i>
                  <a
                    :title="item.title"
                    @click="goDetails(item.id, '通知公告')"
                    >{{ item.title }}</a
                  >
                </template>
              </a-card>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
    <!--新闻资讯-->
    <div class="con-item activity">
      <div class="container">
        <div class="con-title con-title-1">
          <span>新闻资讯</span>
          <a class="more" @click="goRouter('3.4.')">更多资讯</a>
        </div>
        <div class="activity-row">
          <div class="activity-show">
            <a-carousel effect="fade" autoplay dotsClass="activity-dots" v-if="dataActiveCarousel.length">
              <a-card :bordered="false" v-for="(item) in dataActiveCarousel" :key="item.id">
                <template slot="cover">
                  <img
                    alt=""
                    :src="item.imgurl"
                    class="activity-show-first-img"
                    @click="goDetails(item.id, '活动中心')"
                  />
                  <!-- <span>{{ item.pubdate }}</span> -->
                </template>
                <a-card-meta>
                  <template slot="title">
                    <a
                      :title="item.title"
                      class="activity-show-title"
                      @click="goDetails(item.id, '活动中心')"
                      >{{ item.title }}</a
                    >
                  </template>
                  <!-- <template slot="description"> -->
                    <!-- <p v-html="item.digest"></p> -->
                    <!-- <a
                      class="more"
                      @click="goDetails(item.id, '活动中心')"
                      >查看详情
                    </a>
                  </template> -->
                </a-card-meta>
              </a-card>
            </a-carousel>
          </div>
          <div class="activity-list">
            <a-row :gutter="24" class="first-activity">
              <a-col :span="8" class="pic">
                <a-card :bordered="false">
                  <template slot="cover">
                    <img
                      slot=""
                      alt=""
                      :src="dataActive[3] ? dataActive[3].imgurl : ''"
                      @click="goDetails(dataActive[3].id, '活动中心')"
                    />
                    <!-- <span>{{ dataActive[3].pubdate }}</span> -->
                  </template>
                </a-card>
              </a-col>
              <a-col :span="16" class="txt">
                <h3>
                  <a
                    :title="dataActive[3] ? dataActive[3].title : ''"
                    @click="goDetails(dataActive[3].id, '活动中心')"
                    >{{ dataActive[3] ? dataActive[3].title : '' }}</a
                  >
                </h3>
                <!-- <p v-html="dataActive[3].digest"></p> -->
                <!-- <a class="more" @click="goDetails(dataActive[3].id, '活动中心')"
                  >查看详情
                </a> -->
              </a-col>
            </a-row>
            <a-list
              item-layout="horizontal"
              :data-source="dataActive.slice(4, 10)"
            >
              <a-list-item slot="renderItem" slot-scope="item">
                <a-list-item-meta>
                  <a
                    slot="title"
                    :title="item.title"
                    @click="goDetails(item.id, '活动中心')"
                  >
                    {{ item.title }}
                  </a>
                </a-list-item-meta>
                <div class="pubdate">
                  <p>{{ item.date }}<span>-</span>{{ item.year }}</p>
                </div>
              </a-list-item>
            </a-list>
          </div>
        </div>
      </div>
    </div>
    <!-- 横幅 -->
    <div class="streamer">
      <div class="container">
        <a-carousel :autoplay="true" v-if="streamerList.length > 0">
          <div v-for="(item, index) in streamerList" :key="index">
            <a :href="item.imgurl" target="_blank">
              <img :src="item.imgurlPic" alt="">
            </a>
          </div>
        </a-carousel>
      </div>
    </div>
    <!--党建专栏-->
    <div class="con-item party">
      <div class="container">
        <div class="con-title con-title-2">
          <span>党建专栏</span>
          <div v-if="total > 4">
            <a class="more" @click="goRouter('3.2.')">更多内容</a>
          </div>
        </div>
        <div class="party-list">
          <a-button
            shape="circle"
            icon="left"
            class="arrow-l"
            @click="recomPartyLess"
          />
          <a-button
            shape="circle"
            icon="right"
            class="arrow-r"
            @click="recomPartyAdd"
          />
          <a-list :grid="{ gutter: 30, column: 4 }" :data-source="dataParty">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false">
                <template slot="cover">
                  <img
                    alt=""
                    :src="item.imgurl"
                    @click="goDetails(item.id, '党建专栏')"
                  />
                  <!-- <span>{{ item.pubdate }}</span> -->
                </template>
                <a-card-meta>
                  <a slot="title">
                    <span @click="goDetails(item.id, '党建专栏')">{{
                      item.title
                    }}</span>
                  </a>
                </a-card-meta>
              </a-card>
            </a-list-item>
          </a-list>
          <!-- <a-pagination
            simple
            v-model="current"
            v-if="total > 4"
            :pageSize="4"
            @change="changePage"
            :total="total"
          /> -->
        </div>
      </div>
    </div>
    <!--新书快讯-->
    <div class="con-item newbook">
      <div class="container">
        <div class="con-title con-title-2">
          <span>新书快讯</span>
          <div>
            <a class="more" @click="moreBookList">更多新书</a>
          </div>
        </div>
        <a-row :gutter="60" class="newbook-list">
          <a-col :span="8" class="newbook-show">
            <a-row :gutter="30">
              <a-col :span="12" class="book-img">
                <img :src="recommendNewBook.imgurl" alt="" @click="gobookDetails(recommendNewBook.sysFldDoi)" />
                <span>力荐新书</span>
              </a-col>
              <a-col :span="12" class="book-info">
                <h3><a :title="recommendNewBook.name" @click="gobookDetails(recommendNewBook.sysFldDoi)">{{recommendNewBook.name}}</a></h3>
                <p>本书作者：{{recommendNewBook.author}}</p>
                <p>出版时间：{{recommendNewBook.issuedate}}</p>
                <p>产品分类： {{recommendNewBook.sysFldClassfication}}</p>
                <p>内容简介：{{recommendNewBook.digest}}</p>
                </a-col>
            </a-row>
          </a-col>
          <a-col :span="16">
            <a-list :grid="{ gutter: 20, column: 4 }"  :data-source="dataNewbook">
            <a-button
              type="primary"
              shape="circle"
              icon="left"
              class="arrow-l"
              @click="lessChange"
            />
            <a-button
              type="primary"
              shape="circle"
              icon="right"
              class="arrow-r"
              @click="moreChange"
            />
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card hoverable :bordered="false">
                <template slot="cover">
                  <img
                    @click="gobookDetails(item.sysFldDoi)"
                    alt=""
                    :src="item.imgurl"
                  />
                  <span>本月新书</span>
                </template>
                <a-card-meta>
                  <template slot="title">
                    <a :title="item.name" @click="gobookDetails(item.sysFldDoi)">{{item.name}}</a>
                  </template>
                  <template slot="description">
                    作者：{{item.author}}
                  </template>
                </a-card-meta>
              </a-card>
            </a-list-item>
          </a-list>
          </a-col>
        </a-row>
      </div>
    </div>
    <!--重磅推荐-->
    <div class="con-item recbook">
      <div class="container">
        <div class="con-title con-title-2 con-title-white">
          <span>重磅推荐</span>
          <div>
            <a class="more" @click="moreBookList">更多</a>
          </div>
        </div>
        <div class="recbook-list">
          <a-list :data-source="dataRecbook">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false">
                <img
                  @click="gobookDetails(item.sysFldDoi)"
                  slot="cover"
                  alt=""
                  :src="item.imgurl"
                />
                <a-card-meta>
                  <template slot="title">
                    <a :title="item.name" @click="gobookDetails(item.sysFldDoi)">{{item.name}}</a>
                  </template>
                  <template slot="description">
                    作者：{{item.author}}
                  </template>
                </a-card-meta>
              </a-card>
            </a-list-item>
          </a-list>
          <a-button
            shape="circle"
            icon="left"
            class="arrow-l"
            @click="recomBookLess"
          />
          <a-button
            shape="circle"
            icon="right"
            class="arrow-r"
            @click="recomBookAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const orderTheme = (list) => {
  return list.sort((a, b) => a.ordernum - b.ordernum);
};

import * as api from "@/services/index.js";
import moment from "moment";

export default {
  name: "Index",
  components: {},
  data() {
    return {
      visible: false,
      carouselList: [],
      currentPage: ["home"],
      dataNotice: [],
      dataActive: [],
      dataActiveCarousel: [],
      streamerList: [], // 中部横幅
      dataNewbook: [],
      dataRecbook: [],
      dataParty: [],
      recommendNewBook:{}, //力荐新书
      total: 0,
      current: 1,
      pagesize: 3,
      Ddoi: "",
      bookNum: 1,
      bookCount: 0,
      bookDoi: "",
      recommendNum: 1,
      recommendBookCount: 0,
    };
  },
  created() {
    // 获取顶部banner
    this.getAddList()
    // 获取中部横幅
    this.getStreamerList()
    //获取推荐栏目
    this.getRecommendList();
    //查询新书
    this.getNewBook();
  },
  methods: {
    getAddList() {
      api
        .getAddList({
          pageSize: 10,
          pageNumber: 1,
          type:'0'
        })
        .then((res) => {
          if (res.data.success) {
            let list = res.data.data.list || []
            this.carouselList = orderTheme(list)
            this.carouselList.map(function (item) {
              item.imgurlPic =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldFilepath;
            });
          } else {
            this.carouselList = [];
          }
        });
    },
    // 获取中部横幅
    getStreamerList() {
      api
        .getAddList({
          pageSize: 10,
          pageNumber: 1,
          type:'4'
        })
        .then((res) => {
          if (res.data.success) {
            let list = res.data.data.list || []
            this.streamerList = orderTheme(list)
            this.streamerList.map(function (item) {
              item.imgurlPic =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldFilepath;
            });
          } else {
            this.streamerList = [];
          }
        });
    },
    recomPartyLess(){
      if (this.current === 1) {
        // this.$message.warning("已经是第一页了！");
        return;
      }
      this.current--;
      this.changePage();
    },
    recomPartyAdd(){
      if (
        this.current ===
        (this.total % 4 === 0
          ? this.total / 4
          : Math.floor(this.total / 4) + 1)
      ) {
        // this.$message.warning("已经是最后一页了！");
        this.current = 1;
        this.changePage();
        return;
      }
      this.current++;
      this.changePage();
    },
    //图书列表页
    moreBookList(){
      this.$router.push({
          name: "BookList",
          params: {
            value: '',
          },
        });
    },
    //去图书详情
    gobookDetails(doi) {
      this.$router.push({
        name: "BookDetail",
        params: {
          doi: doi,
        },
      });
    },
    //查看新闻详情
    goDetails(doi) {
      this.$router.push({ name: "NewsDetail", params: { doi: doi } });
      if (doi === "全部") {
        this.goRouter("3.3.");
      }
    },
    // 跳转查看更多
    goRouter(id) {
      this.$router.push({ name: "NewsList", params: { id: id } });
    },
    //查询新书
    getNewBook() {
      let obj = {
        pageSize: 4,
        pageNumber: this.bookNum,
      };
      api.getNewBook(obj).then((res) => {
        if (res.data.success) {
          this.dataNewbook = res.data.data.list;
          this.bookCount = res.data.data.count;
          this.dataNewbook.map(function (item) {
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldCoverpath;
            // item.imgurl = require('@/assets/images/book-img1.jpg')
          });
        }
      });
    },
    //图书左按钮
    lessChange() {
      if (this.bookNum === 1) {
        // this.$message.warning("已经是第一页了！");
        return;
      }
      this.bookNum--;
      this.getNewBook();
    },
    //图书右按钮
    moreChange() {
      if (
        this.bookNum ===
        (this.bookCount % 5 === 0
          ? this.bookCount / 5
          : Math.floor(this.bookCount / 5) + 1)
      ) {
        this.bookNum = 1;
        this.getNewBook();
        // this.$message.warning("已经是最后一页了！");
        return;
      }
      this.bookNum++;
      this.getNewBook();
    },
    //图书推荐左按钮
    recomBookLess() {
      if (this.recommendNum === 1) {
        // this.$message.warning("已经是第一页了！");
        return;
      }
      this.recommendNum--;
      this.getRecommendbook(this.bookDoi);
    },
    //图书推荐右按钮
    recomBookAdd() {
      if (
        this.recommendNum ===
        (this.recommendBookCount % 7 === 0
          ? this.recommendBookCount / 7
          : Math.floor(this.recommendBookCount / 7) + 1)
      ) {
        // this.$message.warning("已经是最后一页了！");
        this.recommendNum = 1;
        this.getRecommendbook(this.bookDoi);
        return;
      }
      this.recommendNum++;
      this.getRecommendbook(this.bookDoi);
    },
    //获取推荐图书
    getRecommendbook(doi) {
      let obj = {
        pageSize: 7,
        pageNumber: this.recommendNum,
        columnId: doi,
      };
      api.getRecommendBook(obj).then((res) => {
        if (res.data.success) {
          //通知公告
          this.dataRecbook = res.data.data.list || [];
          this.recommendBookCount = res.data.data.count;

          this.dataRecbook.map(function (item) {
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldCoverpath;
          });
        }
      });
    },
    //获取力荐图书
    getRecommendNewBook(doi) {
      let obj = {
        pageSize: 1,
        pageNumber: 1,
        columnId: doi,
      };
      api.getRecommendNewBook(obj).then((res) => {
        if (res.data.success) {
          //力荐图书
          this.recommendNewBook = res.data.data?res.data.data: {};
          if (this.recommendNewBook.sysFldCoverpath) {
             this.recommendNewBook.imgurl = process.env.VUE_APP_API_BASE_URL + "/download?fileCode=" + this.recommendNewBook.sysFldCoverpath
          }
          // this.recommendBookCount = res.data.data.count;
          // this.dataRecbook.map(function (item) {
          //   item.imgurl =
          //     process.env.VUE_APP_API_BASE_URL +
          //     "/download?fileCode=" +
          //     item.sysFldCoverpath;
          // });
        }
      });
    },
    //获取推荐栏目
    getRecommendList() {
      let vm = this;
      api.getRecommendList().then((res) => {
        if (res.data.success) {
          let list = res.data.data;
          list.map(function (data) {
            if (data.columnname === "首页-新闻资讯-通知公告") {
              //查询推荐  首页通知公告
              vm.getRecommendNote(data.doi, "3.3.");
            }
            if (data.columnname === "首页-新闻资讯-活动中心") {
              //查询推荐  首页活动中心
              vm.getRecommendNote(data.doi, "3.4.");
            }
            if (data.columnname === "首页-新闻资讯-党建专栏") {
              //查询推荐  首页活动中心
              vm.getRecommendNoteD(data.doi, "3.2.");
              vm.Ddoi = data.doi;
            }
            if (data.columnname === "首页-重磅图书推荐") {
              //查询推荐  首页活动中心
              vm.getRecommendbook(data.doi);
              vm.bookDoi = data.doi;
            }
            if (data.columnname === "首页-新书快讯-力荐新书") {
              //首页-新书快讯-力荐新书
              vm.getRecommendNewBook(data.doi);
            }
          });
        }
      });
    },
    // 党建专栏分页
    changePage() {
      let obj = {
        pageSize: 4,
        pageNumber: this.current,
        columnId: this.Ddoi,
        classId: "3.2.",
      };
      api.getRecommendNote(obj).then((res) => {
        if (res.data.success) {
          //党建专栏
          this.dataParty = res.data.data.list || [];
          this.total = res.data.data.count;
          this.pagesize = 4;
          this.dataParty.map(function (item) {
            item.pubdate = moment(item.pubdate).format("YYYY-MM-DD");
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldCoverpath;
          });
        }
      });
    },
    //查询党建专栏
    getRecommendNoteD(doi, id) {
      let obj = {
        pageSize: 4,
        pageNumber: 1,
        classId: id,
        columnId: doi,
      };
      api.getRecommendNote(obj).then((res) => {
        if (res.data.success) {
          if (id === "3.2.") {
            //党建专栏
            this.dataParty = res.data.data.list || [];
            this.total = res.data.data.count;
            this.pagesize = 4;
            this.dataParty.map(function (item) {
              item.pubdate = moment(item.pubdate).format("YYYY-MM-DD");
              item.imgurl =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldCoverpath;
            });
          }
        }
      });
    },
    //查询通知公告
    getRecommendNote(doi, id) {
      let obj = {
        pageSize: 8,
        pageNumber: 1,
        classId: id,
        columnId: doi,
      };
      api.getRecommendNote(obj).then((res) => {
        if (res.data.success) {
          if (id === "3.3.") {
            //通知公告
            this.dataNotice = res.data.data.list || [];
            // if (this.dataNotice.length >= 5) {
            //   this.dataNotice.splice(5, 0, {
            //     id: "全部",
            //     title: "更多通知公告 >",
            //   });
            // } else {
            //   this.dataNotice.splice(this.dataNotice.length, 0, {
            //     id: "全部",
            //     title: "更多通知公告 >",
            //   });
            // }
          }
          if (id === "3.4.") {
            //活动中心
            this.dataActive = res.data.data.list || [];
            this.dataActive.map(function (item) {
              item.imgurl =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldCoverpath;
              const timeOne = new Date(item.pubdate);
              item.date = timeOne.getFullYear();
              const month = (timeOne.getMonth() + 1).toString().padStart(2, 0);
              const day = timeOne.getDate().toString().padStart(2, 0);
              item.year = month + '-' + day
              item.pubdate = moment(item.pubdate).format("YYYY-MM-DD");
              // pubdate
            });
            this.dataActiveCarousel = this.dataActive.slice(0, 3)
          }
        }
      });
    },
    onSearch(value) {
      console.log(value);
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>